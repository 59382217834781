import React, { Component } from "react";
import {
    Route,
    NavLink,
    HashRouter
 } from "react-router-dom";
import Home from "./pages/Home";
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";
 
class Main extends Component {
  render() {
    return (
        <HashRouter>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <div className="frame">
              <div className="header">
                <div className="logo">
                  <div className="logo-image med"></div>
                </div>
                <div className="nav">
                  <div className="nav-button"><NavLink exact to="/">Home</NavLink></div>
                  {/* <div className="nav-button"><NavLink to="/impressum">Impressum</NavLink></div> */}
                  
                  <Route exact path="/" render={() =>(<div className="start-home animation"></div>)}/>
                  {/* <Route path="/impressum" render={() =>(<div className="start-impressum animation"></div>)}/> */}
                </div>
                <div className="login">
                  {/* TBD */}
                </div>
              </div>
              
              <div className="main-container">
                <div className="main">
                  <div className="leftspacer"></div>
                  <div className="content">
                    <Route exact path="/" component={Home}/>
                    <Route path="/impressum" component={Impressum}/>
                    <Route path="/datenschutz" component={Datenschutz}/>
                  </div>
                  <div className="rightspacer"></div>
                  <div className="footer">
                    <div className="logo-image huge"></div>
                    <div className="impressum"><NavLink to="/impressum">Impressum</NavLink></div>
                    <div className="datenschutz"><NavLink to="/datenschutz">Datenschutzerklärung</NavLink></div>
                    <div className="foerderlogos">
                      <div className="logo large sab" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </HashRouter>
    );
  }
}
 
export default Main;