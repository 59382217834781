import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';



const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: {

  },
  middleware: [...getDefaultMiddleware(),  sagaMiddleware],
});

export default store;