import React, { Component } from "react";
import FlipCards from "./components/FlipCards";

class Home extends Component {
  render() {
    return (
      <div className="home-content">
        <div className="bannerbox">
          <div className="bannerstatic"></div>
        </div>
        <FlipCards />

        <div className="glassbox">
          <div className="text">
            <h2>Was ist Adventuries?</h2>
            <p>Adventuries ist eine Plattform zur digitalen Unterstützung für das Geschichtenerzählen.</p>
            <p>Wir bieten Tools für Spielleitungen und Autoren, um ihre Geschichten im Pen & Paper Rollenspiel zu schreiben, zu verwalten, zu leiten und mit der Community zu teilen.</p>
          </div>
        </div>
        <div className="glassbox">
          <div className="text">
            <h2>Warum noch ein Tool, gibt es das nicht schon?</h2>
            <p>Wir legen unseren Fokus sehr stark auf das Geschichtenerzählen, das unterscheidet uns von Virtual Tabletops und anderen Tools. Wir wollen vor allem Spielleitungen helfen ihre Abenteuer bestmöglich interaktiv vorzubereiten und zu leiten.</p>
          </div>
        </div>
        <div className="glassbox">
          <div className="text">
            <h2>Wie helfen mir digitale Tools wenn ich mit meinen Freunden am Tisch spielen will?</h2>
            <p>So toll die Möglichkeiten des Spiels über das Internet sind, wir wollen auch lieber in gemütlicher Runde zusammensitzen und spielen. Unsere SpieleApp soll dich, liebe Spielleitung, am Spieltisch unterstützen, aber nicht die gesellige Runde ersetzen oder ins Internet verlagern.</p>
          </div>
        </div>
        <div className="glassbox">
          <div className="text">
            <h2>Gibt es von euch schon was, das man sich anschauen kann?</h2>
            <p>Unser Experte für das Spielleiten, Chris der Spielpädagoge, hat schon einiges an Content veröffentlicht. </p>
            <p>Besucht ihn doch hier: <a href="https://derspielpaedagoge.de/">Der Spielpädagoge</a></p>
          </div>
          <div className="image-right">
          <a href="https://derspielpaedagoge.de/"><div className="logo huge spielpaedagoge"></div></a>
          </div>
        </div>
        <div className="glassbox">
          <div className="text">
            <h2>Wer seid ihr eigentlich?</h2>
            <p>Wir sind Chris, Klaus und Markus. Drei Rollenspielenthusiasten, die davon träumen das schönste Hobby der Welt noch weiter zu vereinfachen, um gerade dich, liebe Spielleitung, beim Schreiben von Abenteuern und beim Storytelling am Spieltisch zu unterstützen, damit ihr alle noch tiefer in eure ganz persönliche Geschichte eintauchen könnt.</p>
          </div>
        </div>
      </div>
    );
  }
}
export default Home;