import React, { Component } from "react";
 
class Impressum extends Component {
  render() {
    return (
      <div>
        
        <h1>Impressum</h1>
        <h2>Angaben gemäß § 5 TMG</h2>
        <p>
          Adventuries UG (haftungsbeschränkt)<br />
          Suite 14500<br />
          Gro&szlig;enhainer Str. 98<br />
          01127 Dresden<br />
          <br />
          Handelsregister: HRB 43759<br />
          Registergericht: Amtsgericht Dresden<br />
        </p>

        <h2>Vertreten durch die Geschäftsführer:</h2>
        <p>
          Markus Förster<br />
          Christoph Pfitzinger<br />
        </p>

        <h2>Das Impressum gilt auch für folgende Social Media Profile:</h2>
        <p>
          https://www.instagram.com/spielpaedagoge/<br />
          https://www.youtube.com/c/DMTalk1/featured<br />
          https://www.facebook.com/spielpaedagoge<br />
        </p>
       
        <h2>Kontakt</h2>
        <p>
          Telefon: +491624640226<br /> 
          E-Mail: support@adventuries.de<br /> 
        </p>

        <h2>Redaktionell verantwortlich</h2>
        <p>
          Christoph Pfitzinger<br />
          Markus Förster<br />
        </p>

        <h2>EU-Streitschlichtung</h2>
        <p>
          Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:<br />
          https://ec.europa.eu/consumers/odr/.<br />
          Unsere E-Mail-Adresse finden Sie oben im Impressum.<br />
        </p>

        <h2>Verbraucherstreitbeilegung/Universalschlichtungsstelle</h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer<br />
          Verbraucherschlichtungsstelle teilzunehmen.<br />
          <br />
          Quelle:<br />
          e-recht24.de<br />
        </p>

        
        

      </div>
    );
  }
}
 
export default Impressum;
