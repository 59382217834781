import React, { Component } from "react";

class FlipCards extends Component {
  render() {
    return (
        <div className="flipbox">
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <h1>StoryEditor</h1>
                <div className="imgIdea img"></div>
              </div>
              <div className="flip-card-back">
                <h1>StoryEditor</h1>
                <p>Mit unserem StoryEditor kannst du deine Idee ganz einfach aufschreiben, strukturieren und so schnell dein Abenteuer erschaffen.</p>
              </div>
            </div>
          </div>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <h1>SpieleApp</h1>
                <div className="imgPlay img"></div>
              </div>
              <div className="flip-card-back">
                <h1>SpieleApp</h1>
                <p>Hast du dein Abenteuer aufgeschrieben, kannst du es ganz einfach mit unserer SpieleApp abrufen und am Tisch leiten und flexibel auf ungeplante Ideen der Spielenden eingehen.</p>
              </div>
            </div>
          </div>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <h1>Community</h1>
                <div className="imgCommunity img"></div>
              </div>
              <div className="flip-card-back">
                <h1>Community</h1>
                <p>Nach dem Spielen kannst du dein Abenteuer mit der Community teilen, das Abenteuer durch sie erweitern und andere daran teilhaben lassen. So schaffen wir gemeinsam viele neue Geschichten.</p>
              </div>
            </div>
          </div>
        </div>
    );
  }
}
export default FlipCards;